import Section from '@shared/Section'
import classNames from 'classnames/bind'
import styles from './Invitation.module.scss'
import Text from '@shared/Text'

const cx = classNames.bind(styles)

export default function Invitation({ message }: { message: string }) {
  return (
    <Section className={cx('container')}>
      {/* <IconPost className={cx('ico-post')} /> */}
      <Text className={cx('wrap-text')} children={message} />
    </Section>
  )
}

// function IconPost({ className }: { className: string }) {
//   return (
//     <svg
//       className={className}
//       viewBox="0 0 128 128"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path d="M39.22,80.39c.33,3.72,6.8,7.82,7.54,8.28a1,1,0,0,0,1.05,0c.74-.46,7.21-4.56,7.54-8.26,0,0,0-.16,0-.16h0c0-.12,0-.39,0-.4A4.74,4.74,0,0,0,50.77,75a4.49,4.49,0,0,0-3.48,1.67A4.53,4.53,0,0,0,43.8,75a4.74,4.74,0,0,0-4.61,4.84s0,.28,0,.4ZM43.8,77a2.67,2.67,0,0,1,2.52,2.09,1,1,0,0,0,1,.76,1,1,0,0,0,1-.76A2.65,2.65,0,0,1,50.77,77a2.73,2.73,0,0,1,2.61,2.83,2.54,2.54,0,0,1,0,.26v.11c-.17,1.92-3.62,4.81-6.07,6.42-2.46-1.61-5.91-4.5-6.08-6.44V80.1c0-.08,0-.25,0-.25A2.73,2.73,0,0,1,43.8,77Z" />
//       <path d="M78.49,53.52l.21,0c.85-.18,8.32-1.87,9.88-5.24l.07-.15.15-.37h0a5,5,0,0,0-.11-3.65,4.46,4.46,0,0,0-2.6-2.46,4.53,4.53,0,0,0-3.85.4,4.54,4.54,0,0,0-2.72-2.75,4.76,4.76,0,0,0-6,3s-.08.25-.11.38l0,.12c-.94,3.62,3.77,9.66,4.31,10.33A1,1,0,0,0,78.49,53.52ZM75.34,43.29l0-.09.07-.23h0a2.73,2.73,0,0,1,3.42-1.8A2.64,2.64,0,0,1,80.51,44a1,1,0,0,0,.66,1,1,1,0,0,0,1.17-.38,2.66,2.66,0,0,1,3.07-1.13,2.49,2.49,0,0,1,1.45,1.38,3,3,0,0,1,.06,2.17s-.07.17-.09.23l0,.11c-.81,1.75-5,3.31-7.89,4C77.12,49.05,74.85,45.17,75.34,43.29Z" />
//       <path d="M37.27,61.78a1.38,1.38,0,0,0,0,.2,1.36,1.36,0,0,0,0,.2s0,.08,0,.13l0,.06s0,0,0,.05,0,0,0,.06a.75.75,0,0,0,.15.2.3.3,0,0,0,.08.08,1.2,1.2,0,0,0,.24.15l0,0,9.37,3.35H12.56a1,1,0,0,0-1,1v43s0,.08,0,.12a1.42,1.42,0,0,0,0,.2,1,1,0,0,0,.1.18s0,.08,0,.11,0,0,0,0a.88.88,0,0,0,.24.19l.1.07a.91.91,0,0,0,.41.09H82.39a1,1,0,0,0,.41-.09l.09-.07a.92.92,0,0,0,.25-.19l0,0s0-.07,0-.11a.61.61,0,0,0,.09-.18.48.48,0,0,0,0-.2.53.53,0,0,0,0-.12V79.2l20.26,7.24h0a.92.92,0,0,0,.28,0h0a.75.75,0,0,0,.26,0l.11,0a.94.94,0,0,0,.15-.06h0a1,1,0,0,0,.25-.25.41.41,0,0,0,0-.09.83.83,0,0,0,.09-.16L119.4,45.34a1,1,0,0,0-.61-1.28L53,20.56a1,1,0,0,0-.77,0,1,1,0,0,0-.51.56L37.29,61.66A.5.5,0,0,0,37.27,61.78ZM52.83,24.12l14,27.26c.09.16.19.31.28.48a1,1,0,0,0-.73,0l-26.5,8.61Zm50.9,59.12-15-23.46a1,1,0,0,0-.59-.42l.52-.19L116.7,46.94ZM87.81,57.33a14.49,14.49,0,0,1-19.22-6.86l-14-27.22,61.23,21.88ZM13.56,69.71l22.34,21,.42.36a1,1,0,0,0-.7.2l-22.06,17Zm23.28,23.1a1,1,0,0,0,.36-1,16.32,16.32,0,0,0,20.55,0,1,1,0,0,0,.36,1l21.35,16.48h-64Zm22.49-1.58a1,1,0,0,0-.7-.2l.42-.36,22.34-21v38.55Zm-1.65-2a14.5,14.5,0,0,1-20.41,0L15,68.29H80ZM82.39,66.29H53.21L41.33,62,67,53.71a1,1,0,0,0,.68-.85,16.71,16.71,0,0,0,8.45,6.88,16.18,16.18,0,0,0,5.43.93,17.61,17.61,0,0,0,5.47-.9,1,1,0,0,0,0,1.09l14.56,22.71-18.17-6.5V67.29A1,1,0,0,0,82.39,66.29Z" />
//       <path d="M100.31,101a1,1,0,0,0,1-1V97.84a1,1,0,0,0-2,0V100A1,1,0,0,0,100.31,101Z" />
//       <path d="M99.31,108.59a1,1,0,0,0,2,0v-2.15a1,1,0,0,0-2,0Z" />
//       <path d="M105.69,104.21a1,1,0,0,0,0-2h-2.15a1,1,0,0,0,0,2Z" />
//       <path d="M97.08,104.21a1,1,0,0,0,0-2H94.93a1,1,0,0,0,0,2Z" />
//       <path d="M14.92,39.08a1,1,0,0,0,1-1V35.93a1,1,0,0,0-2,0v2.15A1,1,0,0,0,14.92,39.08Z" />
//       <path d="M14.92,47.69a1,1,0,0,0,1-1V44.54a1,1,0,0,0-2,0v2.15A1,1,0,0,0,14.92,47.69Z" />
//       <path d="M18.15,42.31h2.14a1,1,0,0,0,0-2H18.15a1,1,0,0,0,0,2Z" />
//       <path d="M9.54,42.31h2.15a1,1,0,0,0,0-2H9.54a1,1,0,0,0,0,2Z" />
//       <path d="M34.9,22.24a1,1,0,0,0,1-1V19.09a1,1,0,1,0-2,0v2.15A1,1,0,0,0,34.9,22.24Z" />
//       <path d="M34.9,30.84a1,1,0,0,0,1-1V27.69a1,1,0,0,0-2,0v2.15A1,1,0,0,0,34.9,30.84Z" />
//       <path d="M38.12,25.46h2.15a1,1,0,0,0,0-2H38.12a1,1,0,0,0,0,2Z" />
//       <path d="M29.52,25.46h2.15a1,1,0,0,0,0-2H29.52a1,1,0,0,0,0,2Z" />
//       <path d="M111.57,20.21a1.45,1.45,0,1,0-1.45-1.45A1.45,1.45,0,0,0,111.57,20.21Z" />
//       <path d="M114.46,83.36a1.45,1.45,0,1,0-2.89,0,1.45,1.45,0,0,0,2.89,0Z" />
//       <path d="M95.91,27.37a1.45,1.45,0,1,0-1.44-1.45A1.45,1.45,0,0,0,95.91,27.37Z" />
//       <path d="M73.33,19.68a1.45,1.45,0,1,0-1.44-1.44A1.45,1.45,0,0,0,73.33,19.68Z" />
//       <path d="M19.22,24.13a1.45,1.45,0,1,0,0-2.89,1.45,1.45,0,0,0,0,2.89Z" />
//       <path d="M23,54a1.45,1.45,0,1,0,0-2.89A1.45,1.45,0,0,0,23,54Z" />
//     </svg>
//   )
// }
