import React, { useState, useEffect } from 'react'
import classNames from 'classnames/bind'
import styles from './App.module.scss'
import FullScreenMessage from '@shared/FullScreenMessage'
import Heading from '@sections/Heading'
// import Video from '@sections/Video'
import ImageGallery from '@sections/ImageGallery'
import Intro from '@sections/Intro'
import Calendar from '@sections/Calendar'
import Invitation from '@sections/Invitation'
import Map from '@sections/Map'
import Contact from '@sections/Contact'
import Guestbook from '@sections/Guestbook'
import Share from '@sections/Share'
import { Wedding } from '@models/wedding'
import WeddingData from './raw_data.json'
import Snowfall from '@sections/Snowfall'

const cx = classNames.bind(styles)

function App() {
  const [wedding, setWedding] = useState<Wedding | null>(null)
  const [loading, setLoadig] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setLoadig(true)
    if (WeddingData) {
      setWedding(WeddingData.wedding)
    } else {
      setError(true)
    }

    setLoadig(false)
  }, [])

  if (loading) {
    return <FullScreenMessage type="loading" />
  }
  if (error) {
    return <FullScreenMessage type="error" />
  }
  if (!wedding) {
    return null
  }
  const {
    date,
    galleryImages,
    groom,
    bride,
    location,
    message: { intro, invitation },
  } = wedding
  return (
    <div className={cx('container')}>
      {/* <Snowfall /> */}
      <Intro
        groomName={groom.name}
        brideName={bride.name}
        locationName={location.name}
        date={date}
        message={intro}
      />
      <Heading date={date} />
      {/* <Video /> */}
      <Invitation message={invitation} />
      <ImageGallery images={galleryImages} />
      <Calendar date={date} />
      <Map location={location} />
      <Contact groom={groom} bride={bride} />
      <Guestbook />
      <Share groomName={groom.name} brideName={bride.name} date={date} />
    </div>
  )
}

export default App
