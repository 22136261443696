import Section from '@shared/Section'
import classNames from 'classnames/bind'
import styles from './Share.module.scss'
import { useEffect, useState } from 'react'
import { parseISO, format } from 'date-fns'
import { ko } from 'date-fns/locale'
import { CopyToClipboard } from 'react-copy-to-clipboard'

declare global {
  interface Window {
    Kakao: any
  }
}

interface ShareProps {
  groomName: string
  brideName: string
  date: string
}

const cx = classNames.bind(styles)

export default function Share({ groomName, brideName, date }: ShareProps) {
  const weddingDate = parseISO(date)
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const script = document.createElement('script')
    script.crossorigin = 'anonymous'
    script.src = 'https://t1.kakaocdn.net/kakao_js_sdk/2.3.0/kakao.min.js'
    script.async = true

    script.onload = () => {
      if (!window.Kakao.isInitialized()) {
        window.Kakao.init(process.env.REACT_APP_KAKAO_APP_KEY)
        setInitialized(true)
      }
    }

    document.head.appendChild(script)
  }, [])

  const handleShareKakao = () => {
    if (initialized) {
      window.Kakao.Share.sendDefault({
        objectType: 'feed',
        content: {
          title: `${groomName} ♡ ${brideName} 결혼합니다.`,
          description: `${format(
            weddingDate,
            'yyyy년 M월 d일 eeee aaa h시 m분',
            {
              locale: ko,
            },
          )}`,

          imageUrl:
            'https://img.freepik.com/premium-vector/cute-bride-and-groom-couple-in-wedding-dress-cartoon-character_683726-244.jpg?size=626&ext=jpg',
          link: {
            mobileWebUrl: window.location.origin,
            webUrl: window.location.origin,
          },
        },
        buttons: [
          {
            title: '청첩장 보기',
            link: {
              mobileWebUrl: window.location.origin,
              webUrl: window.location.origin,
            },
          },
        ],
      })
    } else {
      alert('Kakao SDK 초기화 중입니다. 잠시 후 다시 시도해 주세요.')
    }
  }

  return (
    <Section className={cx('container')} title="공유하기">
      <div className={cx('wrap-share')}>
        <button onClick={handleShareKakao}>
          <IconKakao />
        </button>
        <CopyToClipboard
          text={window.location.origin}
          onCopy={() => {
            window.alert('복사 완료')
          }}
        >
          <button>
            <IconClipboard />
          </button>
        </CopyToClipboard>
      </div>
    </Section>
  )
}

function IconKakao() {
  return (
    <svg viewBox="0 0 512 512">
      <path d="M235.178,216.211a10.855,10.855,0,0,0-20.323,0L193.93,272.2a6.971,6.971,0,0,0,13.059,4.88l3.341-10.085h28.046a6.464,6.464,0,0,0,1.284-.129l3.384,10.214A6.97,6.97,0,1,0,256.1,272.2ZM216.5,254.425l9.921-29.945,9.921,29.945Z" />
      <path d="M202.965,216.239a6.971,6.971,0,0,0-6.97-6.971H153.423a6.971,6.971,0,0,0,0,13.942h14.315v51.427a6.97,6.97,0,1,0,13.94,0V223.21H196A6.971,6.971,0,0,0,202.965,216.239Z" />
      <path d="M297.1,267.065H277.3V216.239a6.97,6.97,0,1,0-13.94,0v56.37a8.406,8.406,0,0,0,8.4,8.4H297.1a6.97,6.97,0,1,0,0-13.94Z" />
      <path d="M338.962,241.494,359.6,221.548a6.873,6.873,0,0,0-9.554-9.882L324.311,238.43V216.239a7.363,7.363,0,1,0-14.726,0v58.4a7.363,7.363,0,1,0,14.726,0V255.656l4.723-4.566,20.433,27.487a7.265,7.265,0,0,0,11.456-8.938Z" />
      <path d="M256,0C114.615,0,0,114.615,0,256S114.615,512,256,512,512,397.385,512,256,397.385,0,256,0Zm0,358.935a185.433,185.433,0,0,1-28.708-2.225l-57.987,40.667a3.674,3.674,0,0,1-5.663-3.941l13.779-52.516c-40.928-20.55-68.088-56.675-68.088-97.814C109.333,179.135,175,127.277,256,127.277s146.667,51.858,146.667,115.829S337,358.935,256,358.935Z" />
    </svg>
  )
}
function IconClipboard() {
  return (
    <svg viewBox="0 0 24 24">
      <path
        d="M18,4 L19,4 C20.1045695,4 21,4.8954305 21,6 L21,21 C21,22.1045695 20.1045695,23 19,23 L5,23 C3.8954305,23 3,22.1045695 3,21 L3,6 C3,4.8954305 3.8954305,4 5,4 L6,4 C6,2.8954305 6.8954305,2 8,2 L8.99592076,2 C9.74819983,1.36297463 10.8391373,1 12,1 C13.1608627,1 14.2518002,1.36297463 15.0040792,2 L16,2 C17.1045695,2 18,2.8954305 18,4 Z M6.26756439,6 L5,6 L5,21 L19,21 L19,6 L17.7324356,6 C17.3866262,6.59780137 16.7402824,7 16,7 L8,7 C7.25971764,7 6.61337381,6.59780137 6.26756439,6 Z M11,14.5857864 L15.2928932,10.2928932 L16.7071068,11.7071068 L11,17.4142136 L7.29289322,13.7071068 L8.70710678,12.2928932 L11,14.5857864 Z M10.1566481,3.65537749 L9.85761804,4 L9.40134659,4 L8,4 L8,5 L16,5 L16,4 L14.142382,4 L13.8433519,3.65537749 C13.5148073,3.27674041 12.8105871,3 12,3 C11.1894129,3 10.4851927,3.27674041 10.1566481,3.65537749 Z"
        fillRule="evenodd"
      />
    </svg>
  )
}
