import { Swiper, SwiperSlide } from 'swiper/react'
import classNames from 'classnames/bind'
import 'swiper/css'
import styles from './ImageViewer.module.scss'
import './swiper.css'

const cx = classNames.bind(styles)

export default function ImageViewer({
  images,
  open = false,
  selectedIdx,
  onClose,
}: {
  images: string[]
  open: boolean
  selectedIdx: number
  onClose: () => void
}) {
  if (open === false) {
    return null
  }
  return (
    <div className={cx('dimmed')}>
      <CloseButton className={cx('icon-close')} onClose={onClose} />
      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        loop={true}
        initialSlide={selectedIdx}
      >
        {images.map((src, idx) => {
          return (
            <SwiperSlide key={idx}>
              <img src={src} alt="사진 이미지" />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

function CloseButton({
  onClose,
  className,
}: {
  onClose: () => void
  className: string
}) {
  return (
    <svg
      className={className}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClose}
    >
      <g id="Layer_3">
        <path d="M24,0C10.745,0,0,10.745,0,24s10.745,24,24,24s24-10.745,24-24S37.255,0,24,0z M35.997,33.877l-2.122,2.12   L24,26.123l-9.874,9.874l-2.123-2.12l9.876-9.876l-9.876-9.876l2.12-2.122L24,21.88l9.878-9.877l2.119,2.122l-9.875,9.876   L35.997,33.877z" />
      </g>
    </svg>
  )
}
