import React from 'react'

export default function Text({ children }) {
  const lines = children.split('\n')
  const message = lines.map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index === lines.length - 1 ? null : <br />}
    </React.Fragment>
  ))

  return <div>{message}</div>
}
