import React, { useState, useEffect } from 'react'
import Section from '@shared/Section'
import ReactPaginate from 'react-paginate'
import classNames from 'classnames/bind'
import styles from './Guestbook.module.scss'
import { firestore } from '@/firebase'
import { collection, doc, setDoc, getDoc } from 'firebase/firestore'
import { format } from 'date-fns'
import { ko } from 'date-fns/locale'

export default function Guestbook() {
  const [entries, setEntries] = useState([])
  const [newEntry, setNewEntry] = useState({
    name: '',
    role: '신랑에게',
    message: '',
  })
  const [currentPage, setCurrentPage] = useState(1) // currentPage를 1로 시작하도록 수정
  const entryCollection = collection(firestore, 'entries')
  const documentId = process.env.REACT_APP_entryId

  const ITEMS_PER_PAGE = 5 // 페이지당 표시할 항목 수

  const cx = classNames.bind(styles)

  const fetchDataFromFirestore = async () => {
    try {
      const entryDocRef = doc(entryCollection, documentId)
      const docSnapshot = await getDoc(entryDocRef)

      if (docSnapshot.exists()) {
        const data = docSnapshot.data().entries || []
        data.sort((a, b) => (a.id > b.id ? -1 : 1))
        return data
      }
    } catch (error) {
      console.error('Error:', error)
    }
    return []
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchDataFromFirestore() // fetchDataFromFirestore 호출 및 데이터 받음
      if (data) {
        setEntries(data) // 데이터를 설정
      }
    }
    fetchData() // fetchData 함수 호출

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setNewEntry({ ...newEntry, [name]: value })
  }

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1) // 페이지 번호를 1부터 시작
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const entryDocRef = doc(entryCollection, documentId)
      const docSnapshot = await getDoc(entryDocRef)

      if (docSnapshot.exists()) {
        const data = docSnapshot.data().entries || []
        const id = data.length > 0 ? data[data.length - 1].id + 1 : 1 // ID를 1부터 시작
        const currentDate = new Date()

        const newEntryData = {
          id: id,
          name: newEntry.name,
          role: newEntry.role,
          message: newEntry.message,
          date: currentDate.toISOString(),
        }

        if (!newEntryData.name || !newEntryData.message) {
          alert('이름과 메시지를 입력해주세요.')
        } else {
          data.push(newEntryData)
        }

        await setDoc(entryDocRef, { entries: data })
        setEntries(data)
        setNewEntry({ name: '', role: '신랑에게', message: '' })
      } else {
        throw new Error('엔트리 정보 로딩 실패')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const pageCount = Math.ceil(entries.length / ITEMS_PER_PAGE)
  const offset = (currentPage - 1) * ITEMS_PER_PAGE

  return (
    <Section title={'방명록'} className={'container'}>
      <div className={cx('wrap-guestbook')}>
        <div className={cx('wrap-table')}>
          {entries.slice(offset, offset + ITEMS_PER_PAGE).map((entry) => (
            <div key={entry.id} className={cx('message')}>
              <div className={cx('author-date')}>
                <div className={cx('author')}>{entry.name}</div>
                <div className={cx('role')}>{entry.role}</div>
                <div className={cx('date')}>
                  {format(new Date(entry.date), 'yyyy년 MM월 dd일 HH시 mm분', {
                    locale: ko,
                  })}
                </div>
              </div>
              <div className={cx('content')}>{entry.message}</div>
            </div>
          ))}
        </div>
        <div className={cx('pagination')}>
          <ReactPaginate
            previousLabel={'이전'}
            nextLabel={'다음'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        </div>
        <div className={cx('txt-title')}>방명록 작성하기</div>
        <div className={cx('wrap-form')}>
          <form onSubmit={handleSubmit}>
            <div className={cx('wrap-name')}>
              <input
                type="text"
                name="name"
                placeholder="작성자"
                value={newEntry.name}
                onChange={handleInputChange}
              />
              <div className={cx('spacing')}></div>
              <select
                name="role"
                value={newEntry.role}
                onChange={handleInputChange}
              >
                <option value="신랑에게">신랑에게</option>
                <option value="신부에게">신부에게</option>
              </select>
            </div>
            <div className={cx('wrap-textcontent')}>
              <textarea
                name="message"
                placeholder="작성내용"
                value={newEntry.message}
                onChange={handleInputChange}
              />
            </div>
            <div className={cx('wrap-submitBtn')}>
              <button type="submit">작성</button>
            </div>
          </form>
        </div>
      </div>
    </Section>
  )
}
