import Section from '@shared/Section'
import Accordion from '@shared/Accordion'
import classNames from 'classnames/bind'
import styles from './Contact.module.scss'
import { Wedding, Person } from '@models/wedding'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const cx = classNames.bind(styles)

export default function Contact({
  groom,
  bride,
}: {
  groom: Wedding['groom']
  bride: Wedding['bride']
}) {
  return (
    <Section title={'연락처 및 마음 전하실 곳'} className={cx('container')}>
      <Accordion label="신랑측">
        <ContactInfo
          name={groom.name}
          account={groom.account}
          phoneNumber={groom.phoneNumber}
        />
        <ContactInfo
          name={groom.parents[0].name}
          account={groom.parents[0].account}
          phoneNumber={groom.parents[0].phoneNumber}
        />
        <ContactInfo
          name={groom.parents[1].name}
          account={groom.parents[1].account}
          phoneNumber={groom.parents[1].phoneNumber}
        />
      </Accordion>
      <Accordion label="신부측">
        <ContactInfo
          name={bride.name}
          account={bride.account}
          phoneNumber={bride.phoneNumber}
        />
        <ContactInfo
          name={bride.parents[0].name}
          account={bride.parents[0].account}
          phoneNumber={bride.parents[0].phoneNumber}
        />
        <ContactInfo
          name={bride.parents[1].name}
          account={bride.parents[1].account}
          phoneNumber={bride.parents[1].phoneNumber}
        />
      </Accordion>
    </Section>
  )
}

function ContactInfo({ name, account, phoneNumber }: Person) {
  return (
    <div className={cx('wrap-contact')}>
      <div className={cx('wrap-contact-info')}>
        <span className={cx('contact-name')}>{name}</span>
        <span className={cx('contact-bank-name')}>{`${account.bankName}`}</span>
        <span className={cx('contact-account')}>
          {`${account.accountNumber}`}
        </span>
      </div>
      <ul className={cx('wrap-icons')}>
        <li className={cx('wrap-icon')}>
          <a href={`tel:${phoneNumber}`}>
            <IconCall className={cx('button')} />
          </a>
          <span>전화하기</span>
        </li>
        <li className={cx('wrap-icon')}>
          <CopyToClipboard
            text={`${account.bankName} ${account.accountNumber}`}
            onCopy={() => {
              alert('계좌 복사 완료')
            }}
          >
            <button>
              <IconCopy className={cx('button')} />
            </button>
          </CopyToClipboard>
          <span>계좌복사</span>
        </li>
        {account.kakaopayLink != null ? (
          <li className={cx('wrap-icon')}>
            <a
              href={account.kakaopayLink}
              target="_blank"
              rel="noreferer noreferrer"
            >
              <IconKakao className={cx('button')} />
            </a>
            <span>송금하기</span>
          </li>
        ) : null}
      </ul>
    </div>
  )
}

function IconCall({ className }: { className: string }) {
  return (
    <svg viewBox="0 0 512 512" className={className}>
      <g data-name="1" id="_1">
        <path d="M348.73,450.06a198.63,198.63,0,0,1-46.4-5.85c-52.43-12.65-106.42-44.74-152-90.36s-77.71-99.62-90.36-152C46.65,146.75,56.15,99.61,86.69,69.07l8.72-8.72a42.2,42.2,0,0,1,59.62,0l50.11,50.1a42.18,42.18,0,0,1,0,59.62l-29.6,29.59c14.19,24.9,33.49,49.82,56.3,72.63s47.75,42.12,72.64,56.31L334.07,299a42.15,42.15,0,0,1,59.62,0l50.1,50.1a42.16,42.16,0,0,1,0,59.61l-8.73,8.72C413.53,439,383.73,450.06,348.73,450.06ZM125.22,78a12,12,0,0,0-8.59,3.56l-8.73,8.72c-22.87,22.87-29.55,60-18.81,104.49,11.37,47.13,40.64,96.1,82.41,137.86s90.73,71,137.87,82.41c44.5,10.74,81.61,4.06,104.48-18.81l8.72-8.72a12.16,12.16,0,0,0,0-17.19l-50.09-50.1a12.16,12.16,0,0,0-17.19,0l-37.51,37.51a15,15,0,0,1-17.5,2.72c-30.75-15.9-61.75-39.05-89.65-66.95s-51-58.88-66.94-89.63a15,15,0,0,1,2.71-17.5l37.52-37.51a12.16,12.16,0,0,0,0-17.19l-50.1-50.11A12.07,12.07,0,0,0,125.22,78Z" />
        <path d="M364.75,269.73a15,15,0,0,1-15-15,99.37,99.37,0,0,0-99.25-99.26,15,15,0,0,1,0-30c71.27,0,129.25,58,129.25,129.26A15,15,0,0,1,364.75,269.73Z" />
        <path d="M428.15,269.73a15,15,0,0,1-15-15c0-89.69-73-162.66-162.65-162.66a15,15,0,0,1,0-30c106.23,0,192.65,86.43,192.65,192.66A15,15,0,0,1,428.15,269.73Z" />
      </g>
    </svg>
  )
}

function IconCopy({ className }: { className: string }) {
  return (
    <svg viewBox="0 0 48 48" className={className}>
      <path d="M0 0h48v48h-48z" fill="none" />
      <path d="M32 2h-24c-2.21 0-4 1.79-4 4v28h4v-28h24v-4zm6 8h-22c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h22c2.21 0 4-1.79 4-4v-28c0-2.21-1.79-4-4-4zm0 32h-22v-28h22v28z" />
    </svg>
  )
}

function IconKakao({ className }: { className: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24">
      <g>
        <path d="M12,4C5.2,4,0,6.6,0,10v5c0,3.4,5.2,6,12,6c6.8,0,12-2.6,12-6v-5C24,6.6,18.8,4,12,4z M12,6c5.7,0,10,2.1,10,4   s-4.3,4-10,4S2,11.9,2,10S6.3,6,12,6z M19,17.8V16h-2v2.4c-1.2,0.3-2.5,0.5-4,0.6v-2h-2v2c-1.5-0.1-2.8-0.3-4-0.6V16H5v1.8   C3.1,17,2,16,2,15v-1.6C4.1,15,7.7,16,12,16s7.9-1,10-2.6V15C22,16,20.9,17,19,17.8z" />
        <path d="M12,9c2.3,0,4,0.8,4.9,1.4L18.3,9c-1-0.7-3.2-2-6.3-2C8.8,7,6.7,8.2,5.7,9l1.4,1.4C8,9.8,9.6,9,12,9z" />
      </g>
    </svg>
  )
}
