import React, { useState, useEffect } from 'react'
// import { parseISO, format, differenceInDays } from 'date-fns'
import { parseISO, differenceInDays } from 'date-fns'
import Section from '@shared/Section'
import classNames from 'classnames/bind'
import styles from './Heading.module.scss'
import { Message } from '@models/wedding'

const cx = classNames.bind(styles)

export default function Heading({ date }: { date: Message }) {
  const weddingDate = parseISO(date)
  const [daysUntilWedding, setDaysUntilWedding] = useState(0)
  const [timeUntilWedding, setTimeUntilWedding] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  useEffect(() => {
    const today = new Date()
    const daysDifference = differenceInDays(weddingDate, today)
    setDaysUntilWedding(daysDifference)

    const intervalId = setInterval(() => {
      const current_date = new Date().getTime()
      const seconds_left = Math.max((weddingDate - current_date) / 1000, 0) // Ensure non-negative

      const days = Math.floor(seconds_left / (24 * 3600))
      const hours = Math.floor((seconds_left % (24 * 3600)) / 3600)
      const minutes = Math.floor((seconds_left % 3600) / 60)
      const seconds = Math.floor(seconds_left % 60)

      setTimeUntilWedding({
        days,
        hours: String(hours).padStart(2, '0'),
        minutes: String(minutes).padStart(2, '0'),
        seconds: String(seconds).padStart(2, '0'),
      })
    }, 1000)

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId)
  }, [weddingDate])
  return (
    <Section className={cx('container')}>
      {/* <div className={cx('txt-date')}>{format(weddingDate, 'M / d')}</div> */}
      <div className={cx('wrap-dday')}>
        <span>{`D-${daysUntilWedding}`}</span>
      </div>
      <div className={cx('wrap-timer')}>
        <span>{`${timeUntilWedding.hours} : ${timeUntilWedding.minutes} : ${timeUntilWedding.seconds}`}</span>
      </div>
    </Section>
  )
}
